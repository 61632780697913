import React from 'react'


import { useForm, ValidationError } from '@formspree/react';

import { Helmet } from "react-helmet";

const Contact = () => {

  const [state, handleSubmit] = useForm("mknlloey"); //meqbdprd
  if (state.succeeded) {
      return <h2 className="text-4xl text-blue-500 text-center py-36 font-bold">Thanks for contacting!</h2>
      ;
  }


  return (
    <div className='px-4  py-12 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto lg:py-36'>

<Helmet>
        <title>Contact Us - BlackBerry Technologies</title>
        <meta name="description" content="Thank you for your interest in BlackBerry Technologies. Contact Us - Whatever your enquiry, we will help direct you to the right contact information." />
    
      </Helmet>

<div className='flex flex-wrap xl:flex-row justify-between items-start'>

<div className='py-4 lg:py-0 xl:w-1/2'>
    <h3 className='text-2xl  lg:text-[48px] text-custom-black font-bold py-2'>Contact Us</h3>
    <p className='text-md  py-2 lg:text-lg lg:leading-8'>Let's think out loud together. We would love to hear from you.</p>
</div>

<div className='bg-[#175CFF] text-white px-4 lg:px-8 py-2 lg:w-[610px] lg:h-[270px] rounded-md xl:w-1/2'>

    <p className='text-xl font-bold py-2 '>CONTACT INFO</p>
    <p className='text-md'>Give us a call and we will help you out!</p>

    <p className='text-md lg:text-lg py-2'>Phone/WhatsApp: +91 9990945400</p>
    <p className='text-md lg:text-lg py-2' >Email:info@blackberrytechnologies.net</p>
    <p className='text-md lg:text-lg py-2' >Address:H1-181, Assotech Spring Field-Zeta 1, Greater Noida</p>



   
</div>

</div>


{/* form */}
 
<div className='py-16 '>

    <h3 className='text-lg py-2 font-semibold'>FORM</h3>
    <p className='text-md py-2 text-custom-gray'>Get in touch by filling out the form!</p>

    <form onSubmit={handleSubmit} className="py-8" >


    <div className='lg:flex lg:justify-between lg:items-center'>
      
    <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-md font-medium py-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-input w-full lg:w-[400px] xl:w-[500px] 2xl:w-[600px] bg-gray-200 p-2 rounded-lg "
            
          
          />
        </div>

        <div className="mb-4">
          <label htmlFor="company" className="block text-gray-700 text-md font-medium py-2">
            Company
          </label>
          <input
            type="text"
            id="company"
            name="company"
            className="form-input w-full  lg:w-[400px] xl:w-[500px] 2xl:w-[600px] bg-gray-200 p-2 rounded-lg "
           
        
          />
        </div>

    </div>
   
   

   <div className='lg:flex lg:justify-between  lg:items-center'>

   <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 text-md font-medium py-2">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="form-input w-full  lg:w-[400px] xl:w-[500px] 2xl:w-[600px] bg-gray-200 p-2 rounded-lg "
           
 
          />
        </div>


        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-md font-medium py-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-input w-full  lg:w-[400px] xl:w-[500px] 2xl:w-[600px] bg-gray-200 p-2 rounded-lg "
       
      
          />
        </div>
   </div>


   



        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 text-md font-medium py-2">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="form-textarea w-full lg:w-[900px] xl:w-[1100px] 2xl:w-[1300px] h-[200px] bg-gray-200 p-2 rounded-lg "
          
  
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 w-full lg:w-[200px] text-white font-semibold py-2 px-16 rounded-[30px] hover:bg-blue-600"
        >
         Submit
        </button>
      </form>


</div>

    </div>
  )
}

export default Contact