import React from 'react'
import { Link } from 'react-router-dom'

const Button = () => {
  return (
    <div className="py-8">
  <Link to={'/contact-us'} >
  <button className="bg-[#175CFF] px-6 py-4 text-white rounded-[30px]">
      Let's Connect
    </button>

    </Link> 
  </div>
  )
}

export default Button