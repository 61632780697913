import React from 'react'

import about1 from '../about/assets/about1.png'
import about2 from '../about/assets/about2.png'
import about3 from '../about/assets/about3.png'

import member1 from './assets/1.png'
import member2 from './assets/2.png'
import member3 from './assets/3.png'
import member4 from './assets/4.png'
import member5 from './assets/5.png'
import member6 from './assets/6.png'
import Talk from '../../Talk'

import { Helmet } from "react-helmet";


const About = () => {
  return (
    <div className='px-4  py-12 sm:max-w-[600px] md:max-w-[700px]  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto '>

<Helmet>
        <title>About Us - BlackBerry Technologies</title>
        <meta name="description" content="We bring the results while helping you achieve cost and time savings without taking on risk or management overhead. Visit www.blackberrytechnologies.net" />
    
      </Helmet>


   

<div className='flex  flex-col lg:flex-row justify-between'>

<img className=' w-full lg:w-1/2 object-cover' src={about2} alt="" />
    <img className=' w-full lg:w-1/2 object-cover' src={about1} alt="" />

</div>


{/* who we are */}

<div className='bg-[#C0D1F4] p-4 lg:p-16 '>

  <h3 className='text-2xl lg:text-[48px] text-center my-6 font-bold' >Who we are</h3>
  <p className='text-md text-justify lg:text-center pb-16 lg:text-[22px] '>Welcome to BlackBerry Technologies, where technology and innovation converge to transform businesses and drive success. We are a dynamic team of IT professionals with a relentless passion for delivering cutting-edge solutions that empower organizations in the digital age.</p>


<div className='flex lg:flex-row flex-wrap gap-4'>

  <div className='w-[550px] lg:w-[360px] xl:w-[450px] 2xl:w-[550px] 2xl:h-[324px]  bg-[#F6F9FF] px-10 py-6 rounded-xl'>
    <h4 className='text-xl lg:text-[32px] py-2 text-custom-blue font-[600]'>Our Story</h4>
    <p className='txt-md leading-8 lg:text-[22px]'>The story of BlackBerry Technologies began with a vision of addressing the evolving technological needs of businesses. Founded by a group of IT experts, our company was established to bridge the gap between technology and business objectives. We recognized that in the digital landscape, technology is not just a tool but a catalyst for growth and efficiency.</p>
  </div>

  <div className='w-[550px] lg:w-[360px] xl:w-[450px] 2xl:w-[550px] 2xl:h-[324px] bg-[#F6F9FF] px-10 py-6 rounded-xl'>
    <h4 className='text-xl lg:text-[32px] py-2 text-custom-blue font-[600]'>Why Choose us</h4>
    <p className='text-md lg:text-[22px] leading-8'>What sets BlackBerry Technologies apart from the rest? It's our unwavering commitment to understanding your unique business needs and delivering solutions that align with your strategic objectives. We don't just provide IT services; we provide solutions that drive your success.</p>
  </div>



</div>
</div>


{/* what we do */}

<div className=' py-16 lg:py-28'>

  <p className='w-full sm:text-center  lg:text-left   lg:container lg:mx-auto   text-xl lg:text-2xl xl:text-[48px] text-custom-black font-semibold xl:leading-[50px] '>What we do and how we do <br /> our client's works</p>

  <div className='lg:py-12 py-4 xl:flex-row flex flex-wrap  justify-between items-center'>

<div className='flex items-start gap-2 w-[550px] lg:w-[400px] xl:w-[500px]  2xl:w-[550px] py-8'>

<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
  <rect opacity="0.04" x="0.000488281" width="80" height="80" rx="8" fill="#175CFF"/>
  <path d="M31 30.6449C31 29.4127 31.4895 28.231 32.3607 27.3598C33.232 26.4885 34.4137 25.999 35.6458 25.999H40.2917V35.2907H35.6458C34.4137 35.2907 33.232 34.8012 32.3607 33.93C31.4895 33.0587 31 31.877 31 30.6449Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M40.2915 25.999H44.9373C45.5474 25.999 46.1516 26.1192 46.7152 26.3527C47.2789 26.5861 47.791 26.9284 48.2224 27.3598C48.6539 27.7912 48.9961 28.3033 49.2295 28.867C49.463 29.4306 49.5832 30.0348 49.5832 30.6449C49.5832 31.255 49.463 31.8591 49.2295 32.4227C48.9961 32.9864 48.6539 33.4986 48.2224 33.93C47.791 34.3614 47.2789 34.7036 46.7152 34.9371C46.1516 35.1705 45.5474 35.2907 44.9373 35.2907H40.2915V25.999Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M40.2915 39.9364C40.2915 39.3263 40.4117 38.7221 40.6451 38.1585C40.8786 37.5948 41.2208 37.0827 41.6522 36.6513C42.0836 36.2199 42.5958 35.8776 43.1595 35.6442C43.7231 35.4107 44.3272 35.2905 44.9373 35.2905C45.5474 35.2905 46.1516 35.4107 46.7152 35.6442C47.2789 35.8776 47.791 36.2199 48.2224 36.6513C48.6539 37.0827 48.9961 37.5948 49.2295 38.1585C49.463 38.7221 49.5832 39.3263 49.5832 39.9364C49.5832 40.5465 49.463 41.1506 49.2295 41.7143C48.9961 42.2779 48.6539 42.7901 48.2224 43.2215C47.791 43.6529 47.2789 43.9951 46.7152 44.2286C46.1516 44.462 45.5474 44.5822 44.9373 44.5822C44.3272 44.5822 43.7231 44.462 43.1595 44.2286C42.5958 43.9951 42.0836 43.6529 41.6522 43.2215C41.2208 42.7901 40.8786 42.2779 40.6451 41.7143C40.4117 41.1506 40.2915 40.5465 40.2915 39.9364V39.9364Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31 49.2279C31 47.9957 31.4895 46.814 32.3607 45.9428C33.232 45.0715 34.4137 44.582 35.6458 44.582H40.2917V49.2279C40.2917 50.46 39.8022 51.6417 38.9309 52.513C38.0597 53.3842 36.878 53.8737 35.6458 53.8737C34.4137 53.8737 33.232 53.3842 32.3607 52.513C31.4895 51.6417 31 50.46 31 49.2279Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31 39.9364C31 38.7042 31.4895 37.5225 32.3607 36.6513C33.232 35.78 34.4137 35.2905 35.6458 35.2905H40.2917V44.5822H35.6458C34.4137 44.5822 33.232 44.0927 32.3607 43.2215C31.4895 42.3502 31 41.1685 31 39.9364Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div>
  <p className='text-lg text-custom-black'>UI Design</p>
  <p className='text-custom-gray text-md leading-8'>With analytics tools, to help you share with current and future clients.</p>
</div>
</div>

<div className='flex items-start gap-2  w-[550px] lg:w-[400px] xl:w-[500px] 2xl:w-[550px]  py-8'>

<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
  <rect opacity="0.04" width="80" height="80" rx="8" fill="#175CFF"/>
  <path d="M59.001 30L42.1601 46.625L33.2964 37.875L20.001 51" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M48.0007 30.001H59.0007V41.001" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<div>
  <p className='text-lg text-custom-black'>UI Strategy</p>
  <p className='text-custom-gray text-md leading-8'>Total collections, quoting, enrollment, and reporting in Italian and English.</p>
</div>
</div>


<div className='flex items-start gap-2  w-[550px] lg:w-[400px] xl:w-[500px] 2xl:w-[550px]  py-8'>

<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
  <rect opacity="0.04" y="-0.00146484" width="80" height="80" rx="8" fill="#175CFF"/>
  <path d="M36.454 52.9991C37.1067 52.9991 37.6359 52.4448 37.6359 51.761C37.6359 51.0773 37.1067 50.5229 36.454 50.5229C35.8013 50.5229 35.2722 51.0773 35.2722 51.761C35.2722 52.4448 35.8013 52.9991 36.454 52.9991Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M49.4543 52.9991C50.107 52.9991 50.6361 52.4448 50.6361 51.761C50.6361 51.0773 50.107 50.5229 49.4543 50.5229C48.8016 50.5229 48.2725 51.0773 48.2725 51.761C48.2725 52.4448 48.8016 52.9991 49.4543 52.9991Z" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27 26.999H31.7273L34.8945 43.577C35.0026 44.147 35.2986 44.659 35.7307 45.0235C36.1629 45.3879 36.7036 45.5815 37.2582 45.5703H48.7455C49.3001 45.5815 49.8408 45.3879 50.2729 45.0235C50.705 44.659 51.001 44.147 51.1091 43.577L53 33.1895H32.9091" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<div>
  <p className='text-lg text-custom-black'>Branding</p>
  <p className='text-custom-gray text-md leading-8'>All services for our team of industry experts, personal training, support line and help desk.</p>
</div>
</div>



<div className='flex items-start gap-2  w-[550px] lg:w-[400px] xl:w-[500px] 2xl:w-[550px]  py-8'>

<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
  <rect opacity="0.04" y="-0.000488281" width="80" height="80" rx="8" fill="#175CFF"/>
  <rect x="26.0005" y="25.999" width="27" height="20" rx="2" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M40.5017 46.9995V52.0021" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M35.0024 52.4995H45.0024" stroke="#175CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<div>
  <p className='text-lg text-custom-black'>Web Development</p>
  <p className='text-custom-gray text-md leading-8'>We can help you set up and manager your groups if you are become our partner.</p>
</div>
</div>


  </div>
</div>




  {/* why blackberry */}

<div className='flex flex-col my-4 lg:flex-row  lg:justify-between lg:gap-x-16 items-start   '>

 <img className='w-full lg:w-1/2' src={about3} alt="" />


<div className='  lg:w-1/2 w-full' >
  <p className='text-md text-[#175CFF] font-bold lg:p-2  '  >WHY BLACKBERRY TECHNOLOGIES</p>
  <p className='text-xl py-2 lg:text-2xl xl:text-[48px] text-[#0A083B] font-semibold lg:leading-[40px] '>Your IT partner</p>
  <p className='text-[#0A083B] text-md lg:text-lg text-justify leading-8 lg:leading-10 xl:leading-[40px] max-w-[520px]' > Thank you for considering BlackBerry Technologies as your trusted IT partner. Whether you're a small business or a large enterprise, we are here to empower your journey through technology. We look forward to collaborating with you, understanding your IT needs, and delivering the solutions that drive your success.
 </p>
</div>



</div>




   {/* lets talk */}

   <Talk />


    </div>
  )
}

export default About