import React, { useState } from "react";
import { useForm, ValidationError } from '@formspree/react';

const Talk = () => {

 

  const [state, handleSubmit] = useForm("mknlloey");
  if (state.succeeded) {
      return <h2 className="text-4xl text-blue-500 text-center py-36 font-bold">Thanks for contacting!</h2>
      ;
  }

  return (

    <div className="bg-[#17154B]">

 
    <div className="flex flex-col lg:flex-row flex-wrap justify-between items-start  px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto 8 py-8 sm:py-12">
    
      <div className="lg:w-1/3 py-8 pl-4 lg:px-20 lg:py-10 ">
        <p className="text-2xl text-center lg:text-left  text-white font-semibold py-4 2xl:text-[48px] ">Let's Talk</p>
        <p className="text-md text-center lg:text-left lg:text-md text-white 2xl:text-[18px] xl:w-[400px]">
          Got something in mind? We'd love to help. Tell us about your project
          requirement
        </p>
      </div>

 
{/* form */}

<div className="w-full lg:w-2/3 lg:max-w-lg lg:mx-auto lg:p-4 ">

 

    <form onSubmit={handleSubmit} className="py-8" >



      
    <div className="mb-4">
          <label htmlFor="name" className="block text-white text-md font-medium py-2">
            Name <sup className="text-white text-md">*</sup>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-input w-full text-white bg-[#17154B]  border-b-2 border-white p-2  "
            
          
          />
        </div>

        <div className="mb-4">
          <label htmlFor="company" className="block text-white text-md font-medium py-2">
            Company
          </label>
          <input
            type="text"
            id="company"
            name="company"
            className="form-input w-full text-white  bg-[#17154B]  border-b-2 border-white  p-2  "
           
        
          />
        </div>


   
   

   <div className='lg:flex lg:justify-between  lg:items-center'>

   <div className="mb-4">
          <label htmlFor="phone" className="block text-white text-md font-medium py-2">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="form-input text-white w-full lg:w-[220px]  bg-[#17154B]  border-b-2 border-white  p-2  "
           
 
          />
        </div>


        <div className="mb-4">
          <label htmlFor="email" className="block text-white text-md font-medium py-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-input text-white w-full lg:w-[220px] bg-[#17154B]  border-b-2 border-white  p-2  "
       
      
          />
        </div>
   </div>


   



        <div className="mb-4">
          <label htmlFor="message" className="block text-white text-md font-medium py-2">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="form-input w-full text-white   bg-[#17154B]  border-b-2 border-white  p-2  "
          
  
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 w-full lg:w-[200px] text-white font-semibold py-2 px-16 rounded-[30px] hover:bg-blue-600"
        >
         Submit
        </button>
      </form>


</div>


    </div>

    </div>



  );
};

export default Talk;
