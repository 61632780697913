import React from "react";
import cloud from "./assets/cloud.png";
import Talk from "../Talk";
import Button from "../Button";
import { Helmet } from "react-helmet";

const Cloud = () => {
  return (
    <div>
      <Helmet>
        <title>Cloud Services & Solutions - BlackBerry Technologies</title>
        <meta
          name="description"
          content="Explore how BlackBerry Technologies cloud services & solutions will help your digital transformation – from tailored cloud platforms to cloud migration support."
        />
      </Helmet>

      <div className="bg-cloud">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2 text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
                Cloud Services
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              Our robust cloud solutions optimize scalability, security, and accessibility for your organization.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={cloud}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
      <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Desktop as a Service
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Being one of the top providers, we facilitate continuous improvement, on cloud services adoption/migration, and automation as per your business requirements.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px]  h-[310px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Intuit Authorised Quickbooks Hosting
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Making finance simpler and accessible through any time, anywhere, accounting access. With Intuit authorized support for quick turnaround time.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Data Recovery as a Service
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Our top-of-the-line disaster recovery (DR) protects critical workloads, is competitive, and keeps a tight integration with the system center.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Application Hosting
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Host your apps and own the power of automation. Access your applications painlessly, auto upgrade, and easily integrate with your available systems.
            </p>
          </div>
        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between items-center">
            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Secured virtualizatio
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Free your business from the cost and burden of data center operations.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Right Cloud Strategy
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Let our specialists help you accelerate the value of the cloud.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Top-notch Support
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Reduce your risk of cybersecurity attacks and business-impacting downtime.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Cloud;
