import React from 'react';
import logo from './assets/logo.png';
import { AiFillTwitterCircle, AiFillInstagram, AiFillFacebook } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#F6F9FF] ">
    
    <div className='text-black py-16  px-4 sm:max-w-[600px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto'>

  
    
      <div className="container mx-auto flex flex-col lg:flex-row justify-between">
        {/* Section 1 */}
        <div className="lg:w-2/5">
          <img className="w-52" src={logo} alt="logo" />
          <p className="font-semibold pt-8   ">+91 99998 43213</p>
          <p className="font-semibold py-4 ">info@blackberrytechnologies.net</p>
        </div>

        {/* Section 2 */}
        <div className="pt-8 lg:w-1/5">
          <h3 className="text-lg font-bold mb-2">Menu</h3>
          <ul>
        <Link to={'/'}>   <li className="py-2 ">Home</li> </Link> 
        <Link to={'/about-us'}>     <li className="py-2 ">About Us</li> </Link> 
     
            <Link to={'/contact-us'}>     <li className="py-2 text-md">Contact</li> </Link> 
          </ul>
        </div>

        {/* Section 3 */}
        <div className=" lg:py-8  lg:w-1/5">
          <ul className="lg:relative lg:top-8">
        
          </ul>
        </div>

        {/* Section 4 */}
        <div className="py-8 lg:w-1/5 lg:py-6">
          <h3 className="text-lg font-bold mb-2">Services</h3>
          <ul>
         <Link to={'/IT-services'}>  <li className="py-2 ">IT Services</li> </Link> 
      
         <Link to={'/cloud-services-and-solutions'}>    <li className="py-2 ">Cloud</li> </Link> 
         <Link to={'/digital-product-design'}>    <li className="py-2">Design</li> </Link> 
         <Link to={'/cyber-security-services'}>    <li className="py-2">Cybersecurity</li> </Link> 
         <Link to={'/fintech-services'}>    <li className="py-2">Fintech Services</li> </Link> 
         <Link to={'/strategy-services'}>    <li className="py-2">Strategy Services</li> </Link> 
         <Link to={'/software-development-services'}>    <li className="py-2">Software Development</li> </Link> 
         <Link to={'/application-development-services'}>    <li className="py-2">Application Developement</li> </Link> 
         <Link to={'/document-management-systems'}>    <li className="py-2">Document Management Systems</li> </Link> 
       
          </ul>
        </div>

  
      </div>

      <hr className="my-2 lg:my-20 bg-[#000]"  />

      <div className="flex pt-4 lg:pt-0 flex-col gap-y-4 lg:flex-row justify-between items-center">
        {/* <div className="flex gap-4">
          <AiFillFacebook size={30} />
          <AiFillTwitterCircle size={30} />
          <AiFillInstagram size={30} />
        </div> */}

<div>
          <p className="font-semibold">Design & Developed By <Link target='_blank' to={'https://www.papermoon.in/'}> <span className='font-bold'>PaperMoon</span> </Link></p> 
        </div>

        <div>
          <p className="font-semibold">© {currentYear} BlackBerryTechnologies. All rights Reserved</p>
        </div>
      </div>


      </div>
    </footer>
  );
};

export default Footer;
