import React from "react";
import strategy from "./assets/strategy.png";
import Talk from "../Talk";
import Button from "../Button";

import { Helmet } from "react-helmet";

const Strategy = () => {
  return (
    <div>
      <Helmet>
        <title>Strategy Services & Solutions - BlackBerry Technologies</title>
        <meta
          name="description"
          content="BlackBerry Technologies advises clients on developing strategic opportunities by providing in-depth market, competitor, and customer insights."
        />
      </Helmet>

      <div className="bg-strategy">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2 text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
              Strategy Services
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              Analyze your business goals, and acquire a thorough technology roadmap.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={strategy}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
      <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-16 items-center py-8">
          <div className="bg-[#F6F9FF]  w-[370px] h-[420px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              Pinpoint Goals
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
              <li>Strategic Consulting</li>
              <li>Financial Planning and Analysis</li>
              <li>Budgeting and forecasting</li>
              <li>Enterprise Risk and Compliance</li>
              <p>Services</p>
              <li>Financial Analytics</li>
              <li>Management & Reporting</li>
              <li>Quantitative Research Services</li>
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[420px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              Data Details
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
              <li>SAP</li>
              <li>NetSuite</li>
              <li>Microsoft Dynamics GP</li>
              <li>Intuit</li>

              <li>OneSite RealPage</li>
              <li>Other Finance Management </li>
              <p className="pl-6">
                Software tools like bill.com,
                <br /> Expensify, Concur, Yardi Voyager
              </p>
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[420px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              Company Uniqueness
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
              <li>KPO Financial Services</li>
              <li>Procure to Payment (P2P)</li>
              <li>Order to Cash (O2C)</li>
              <li>Record to report (R2R)</li>

              <li>Intercompany and Joint Venture Accounting</li>
              <li>Payroll and Employee Benefits Administration</li>

              <li>Travel & Expense Processing</li>

              <li>Analysing Financial Statements</li>
            </p>
          </div>
        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between  items-start">
            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Digital Transformatio
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Identify and deploy cloud solutions to optimize processes and foster business growth.
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Process Innovation
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              We set guidelines, benchmarks and motivators to keep your team moving forward and your goals on target.
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Microsoft Power BI
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Fully utilize your data for analysis, reporting to evolve your business.
              </p>
            </div>

        
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Strategy;
