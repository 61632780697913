import React, { useState,  Fragment } from 'react';
import logo from './assets/logo.png';
import { Link } from 'react-router-dom';
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";

function Navbar() {
 
  const [open, setOpen] = useState(false);




  return (
    <nav className=" bg-white py-4 md:py-8 px-4 sm:max-w-[600px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto   ">
      <div>
        <div className="flex justify-between  items-center">

          <Link to={'/'}>
            <img className="w-[200px] md:w-[268px]" src={logo} alt="logo" />
          </Link>

          <div className="hidden lg:flex lg:items-center lg:gap-8">
            <Link to={'/'} className="text-[#57586E] text-base md:text-lg font-bold text-[16px]">
              Home
            </Link>


<ul>
 
 <li className="relative group">
   <span className=" text-[#57586E]  text-base md:text-lg cursor-pointer font-bold text-[16px]">
   Our Services
   </span>

   {/* Dropdown menu */}

   <ul className="hidden absolute text-md top-7  z-10 bg-white py-16 px-2  group-hover:block text-left">
  <div className='grid grid-cols-3 gap-4 w-[400px]'>
    <div>
      <li className="mb-2">
        <Link to={"/IT-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100"> IT Services </Link>
      </li>
      <li className="mb-2">
        <Link to={"/software-development-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Software Development</Link>
      </li>
      <li className="mb-2">
        <Link to={"/cloud-services-and-solutions"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Cloud Services</Link>
      </li>
    </div>
    
    <div>
      <li className="mb-2">
        <Link to={"/digital-product-design"} className="block px-4 py-2 text-[16px] hover:bg-gray-100"> Design Services</Link>
      </li>
      <li className="mb-2">
        <Link to={"/cyber-security-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Cyber Security</Link>
      </li>
      <li className="mb-2">
        <Link to={"/fintech-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Fintech Services</Link>
      </li>
    </div>
    
    <div>
      <li className="mb-2">
        <Link to={"/strategy-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100"> Strategy Services</Link>
      </li>
      <li className="mb-2">
        <Link to={"/document-management-systems"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Document Management Systems</Link>
      </li>
      <li className="mb-2">
        <Link to={"/application-development-services"} className="block px-4 py-2 text-[16px] hover:bg-gray-100">Application Development</Link>
      </li>
    </div>
  </div>
</ul>




 </li>

</ul>

  
            <Link to={'/about-us'} className="text-[#57586E] font-bold text-base md:text-[16px] ">
              About Us
            </Link>
            <Link to={'/contact-us'} className="text-[#175CFF] px-6 py-2 md:px-8 md:py-3 font-bold text-base md:text-[16px] border-2 border-[#175CFF] rounded-full hover:bg-[#175CFF] hover:text-white">
              Contact Us
            </Link>
          </div>


   {/* for mobile  */}


   <button
  type="button"
  className="rounded-md bg-gray-300 p-2 text-black lg:hidden"
  onClick={() => setOpen(true)}
>
  <span className="sr-only">Open menu</span>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="w-6 h-6"
    onClick={() => setOpen(true)}  
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
  </svg>
</button>



    


   <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bg-white inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto  pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-12">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-black"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <RxCross2 size={30} className="relative bottom-2" />
                  </button>
                </div>

                <div   className="space-y-6 border-t border-black p-4 ">
                  <Link to={"/"} className="text-sm font-medium text-gray-900 " onClick={() => setOpen(false)}>
                    Home
                  </Link>

            

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/about-us"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                About Us
                    </Link>
                  </div>

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/contact-us"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                 Contact
                    </Link>
                  </div>

           
                  <div className="flow-root">
                  <ul>
  <li className="relative group">
    <span className="text-sm font-medium text-gray-900">
      Our Services
    </span>

    {/* Dropdown menu */}
    <ul className="hidden text-md z-10 bg-white py-4 px-8 space-y-2 group-hover:block">
      <div className="flex flex-col items-start">
        <li>
          <Link to="/IT-services" onClick={() => setOpen(false)} className="block px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100">
            IT Services
          </Link>
        </li>
        <li>
          <Link to="/software-development-services" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Software Development
          </Link>
        </li>
        <li>
          <Link to="/cloud-services-and-solutions" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Cloud Services
          </Link>
        </li>
        <li>
          <Link to="/digital-product-design" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Design Services
          </Link>
        </li>
        <li>
          <Link to="/cyber-security-services" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Cyber Security
          </Link>
        </li>
        <li>
          <Link to="/fintech-services" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Fintech Services
          </Link>
        </li>
        <li>
          <Link to="/strategy-services" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Strategy Services
          </Link>
        </li>
        <li>
          <Link to="/document-management-systems" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Document Management Systems
          </Link>
        </li>
        <li>
          <Link to="/application-development-services" onClick={() => setOpen(false)} className="text-sm font-medium text-gray-900 block px-4 py-2 hover:bg-gray-100">
            Application Development
          </Link>
        </li>
      </div>
    </ul>
  </li>
</ul>



                  </div>


          


                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>





        </div>
      </div>
    </nav>
  );
}

export default Navbar;
