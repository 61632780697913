import React from "react";
import Hero from "./Hero";

import Services from "./Services";
import WhyChose from "./WhyChose";
import Newsletter from "../Newsletter";
import Subscribe from "../Subscribe";

const Home = () => {
  return (
    <div>
      <Hero />

      <Services />
      <WhyChose />

      {/* subscribe */}

     <Newsletter />




    </div>
  );
};

export default Home;
