import React from 'react'
import { useState } from 'react';

const PricingCard = () => {

  const [isMonthly, setIsMonthly] = useState(true);

  const MonthlyPricingCard = () => {
    return (
      <div className="mt-24 p-4 flex justify-between ">
        <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
          <div className="flex  items-end px-2 py-4">
            <p className="text-4xl font-bold ">$29 </p>
            <span className="text-md text-custom-gray">/Month</span>
          </div>
  
          <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
          <p className="text-justify px-4 ">
            All the basics for businesses that are just getting started.
          </p>
  
          <div className="pt-16 px-2">
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">One Time Project</p>
            </div>
  
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">Your Dashboard</p>
            </div>
  
            <div className="py-8 pb-8">
              <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
                Get Easy
              </button>
            </div>
          </div>
  
          <div></div>
        </div>
  
        <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
          <div className="flex  items-end px-2 py-4">
            <p className="text-4xl font-bold ">$29 </p>
            <span className="text-md text-custom-gray">/Month</span>
          </div>
  
          <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
          <p className="text-justify px-4 ">
            All the basics for businesses that are just getting started.
          </p>
  
          <div className="pt-16 px-2">
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">One Time Project</p>
            </div>
  
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">Your Dashboard</p>
            </div>
  
            <div className="py-8 pb-8">
              <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
                Get Easy
              </button>
            </div>
          </div>
  
          <div></div>
        </div>
  
        <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
          <div className="flex  items-end px-2 py-4">
            <p className="text-4xl font-bold ">$29 </p>
            <span className="text-md text-custom-gray">/Month</span>
          </div>
  
          <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
          <p className="text-justify px-4 ">
            All the basics for businesses that are just getting started.
          </p>
  
          <div className="pt-16 px-2">
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">One Time Project</p>
            </div>
  
            <div className="flex gap-2 py-2 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.15"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                  fill="#36B37E"
                />
                <path
                  d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                  fill="#36B37E"
                />
              </svg>
              <p className="text-custom-black text-md">Your Dashboard</p>
            </div>
  
            <div className="py-8 pb-8">
              <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
                Get Easy
              </button>
            </div>
          </div>
  
          <div></div>
        </div>
      </div>
    );
  };
  
  const YearlyPricingCard = () => {
    return (
   
      <div className="mt-24 p-4 flex justify-between ">
      <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
        <div className="flex  items-end px-2 py-4">
          <p className="text-4xl font-bold ">$300 </p>
          <span className="text-md text-custom-gray">/Year</span>
        </div>

        <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
        <p className="text-justify px-4 ">
          All the basics for businesses that are just getting started.
        </p>

        <div className="pt-16 px-2">
          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">One Time Project</p>
          </div>

          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">Your Dashboard</p>
          </div>

          <div className="py-8 pb-8">
            <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
              Get Easy
            </button>
          </div>
        </div>

        <div></div>
      </div>

      <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
        <div className="flex  items-end px-2 py-4">
          <p className="text-4xl font-bold ">$300 </p>
          <span className="text-md text-custom-gray">/Year</span>
        </div>

        <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
        <p className="text-justify px-4 ">
          All the basics for businesses that are just getting started.
        </p>

        <div className="pt-16 px-2">
          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">One Time Project</p>
          </div>

          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">Your Dashboard</p>
          </div>

          <div className="py-8 pb-8">
            <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
              Get Easy
            </button>
          </div>
        </div>

        <div></div>
      </div>

      <div className=" py-8 px-4 border-[1.7px] border-gray-300 shadow-lg rounded-lg w-[360px] h-[500px]">
        <div className="flex  items-end px-2 py-4">
          <p className="text-4xl font-bold ">$500 </p>
          <span className="text-md text-custom-gray">/Year</span>
        </div>

        <p className="text-justify text-2xl  font-semibold py-4 px-4 ">Easy</p>
        <p className="text-justify px-4 ">
          All the basics for businesses that are just getting started.
        </p>

        <div className="pt-16 px-2">
          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">One Time Project</p>
          </div>

          <div className="flex gap-2 py-2 items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.15"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24.0005C18.6274 24.0005 24 18.6279 24 12.0005C24 5.37307 18.6274 0.000488281 12 0.000488281C5.37258 0.000488281 0 5.37307 0 12.0005C0 18.6279 5.37258 24.0005 12 24.0005Z"
                fill="#36B37E"
              />
              <path
                d="M10.3994 15.8252L7.13769 12.583C7.04655 12.4919 7.00098 12.3747 7.00098 12.2314C7.00098 12.0882 7.04655 11.971 7.13769 11.8799L7.86035 11.1768C7.9515 11.0726 8.06543 11.0205 8.20215 11.0205C8.33886 11.0205 8.45931 11.0726 8.56347 11.1768L10.751 13.3642L15.4385 8.67676C15.5426 8.57259 15.6631 8.52051 15.7998 8.52051C15.9365 8.52051 16.0504 8.57259 16.1416 8.67676L16.8642 9.37988C16.9554 9.47103 17.001 9.58821 17.001 9.73144C17.001 9.87467 16.9554 9.99186 16.8642 10.083L11.1025 15.8252C11.0114 15.9293 10.8942 15.9814 10.751 15.9814C10.6077 15.9814 10.4906 15.9293 10.3994 15.8252Z"
                fill="#36B37E"
              />
            </svg>
            <p className="text-custom-black text-md">Your Dashboard</p>
          </div>

          <div className="py-8 pb-8">
            <button className="py-3 border-2 border-blue-500 text-blue-500 text-lg rounded-[30px] font-semibold w-full">
              Get Easy
            </button>
          </div>
        </div>

        <div></div>
      </div>
    </div>

    );
  };

  const handleSwitch = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className="text-center mt-4">
        <div className="flex justify-center items-center space-x-4">
          <button
            onClick={handleSwitch}
            className={`${
              isMonthly ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            } py-2 px-4 rounded-md focus:outline-none`}
          >
            Bill Monthly
          </button>
          <button
            onClick={handleSwitch}
            className={`${
              !isMonthly
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            } py-2 px-4 rounded-md focus:outline-none`}
          >
            Bill Yearly
          </button>
        </div>
        {isMonthly ? <MonthlyPricingCard /> : <YearlyPricingCard />}
      </div>
  )
}

export default PricingCard


