import React from "react";
import application from "./assets/application.png";
import Talk from "../Talk";
import Button from "../Button";
import { Helmet } from "react-helmet";

const Application = () => {
  return (
    <div>
      <Helmet>
        <title>Application Development Services | Web & Mobile Apps</title>
        <meta
          name="description"
          content="BlackBerry Technologies, a top web and mobile app development company in India, offers end-to-end application development services. Click to know more!"
        />
      </Helmet>

      <div className="bg-application">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2  leading-[60px] text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
                Application Development
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              We build stunning apps that capture the users' imaginations.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={application}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
        
        <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4     lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              Mobile & Web Applications
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Find all the information with a click of a button with our software, hardware, comprehensive training & implementation and get your paper under control.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            E-Commerce
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Secure document scanning and imaging services that unlock the value of all the information stored in their file cabinets, storage boxes and paper documents.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Enterprise and Custom Application
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Speed up your business’s workflow by capturing, transferring and streamlining how you collect information with automated forms.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            SAAS implementation
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Increase efficiency and productivity by adding a scanner to your office. Stop worrying about not being able to find information and increase efficiency.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Agile Methodology
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Our custom software development services and programming solutions can be tailored to your company's specific document management requirements, resulting in increased productivity.
            </p>
          </div>
        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between  items-start">
            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Front-End Development
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Implementing the best combination of technologies (full-stack, mean stack, flutter, native-OS) for your project.
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Web and Mobile Applications
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Implementing the perfect blend of agile development to create dynamic and responsive web and mobile applications.
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              CMS Integration
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Building a platform that is accessible and can be managed by the customers in order to create, manage and modify digital content on their website easily.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Application;
