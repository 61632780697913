import React from 'react'

import { Link } from 'react-router-dom'

const Services = () => {



  return (
    <div className="lg:py-36 sm:max-w-[600px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px]  2xl:max-w-[1300px] sm:container sm:mx-auto py-8 px-4 ">

    

    <h3 className="text-custom-black text-2xl lg:text-4xl font-semibold py-2">What we provide</h3>
    <p className="text-md lg:text-lg py-2 leading-6 text-justify lg:leading-8">
      From fortifying your cybersecurity to harnessing the power of the cloud,
      our expert team is dedicated to delivering solutions that align with
      your unique goals. Explore a range of services crafted to optimize your
      technology landscape, streamline operations, and drive growth.
    </p>

    <div className=" flex  flex-wrap   justify-center md:justify-between lg:justify-center lg:gap-20 2xl:gap-12  items-center py-20 ">

 {/* service cards */}

      <div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

        <div className="flex gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
<path d="M48.3 5H11.7C8.025 5 5 8.025 5 11.7V18.275C5 21.975 8.025 25 11.7 25H48.275C51.975 25 55 21.975 55 18.3V11.7C55 8.025 51.975 5 48.3 5ZM16.875 17.5C16.875 18.525 16.025 19.375 15 19.375C13.975 19.375 13.125 18.525 13.125 17.5V12.5C13.125 11.475 13.975 10.625 15 10.625C16.025 10.625 16.875 11.475 16.875 12.5V17.5ZM26.875 17.5C26.875 18.525 26.025 19.375 25 19.375C23.975 19.375 23.125 18.525 23.125 17.5V12.5C23.125 11.475 23.975 10.625 25 10.625C26.025 10.625 26.875 11.475 26.875 12.5V17.5ZM45 16.875H35C33.975 16.875 33.125 16.025 33.125 15C33.125 13.975 33.975 13.125 35 13.125H45C46.025 13.125 46.875 13.975 46.875 15C46.875 16.025 46.025 16.875 45 16.875Z" fill="url(#paint0_linear_85_477)"/>
<path d="M48.3 35H11.7C8.025 35 5 38.025 5 41.7V48.275C5 51.975 8.025 55 11.7 55H48.275C51.975 55 54.975 51.975 54.975 48.3V41.725C55 38.025 51.975 35 48.3 35ZM16.875 47.5C16.875 48.525 16.025 49.375 15 49.375C13.975 49.375 13.125 48.525 13.125 47.5V42.5C13.125 41.475 13.975 40.625 15 40.625C16.025 40.625 16.875 41.475 16.875 42.5V47.5ZM26.875 47.5C26.875 48.525 26.025 49.375 25 49.375C23.975 49.375 23.125 48.525 23.125 47.5V42.5C23.125 41.475 23.975 40.625 25 40.625C26.025 40.625 26.875 41.475 26.875 42.5V47.5ZM45 46.875H35C33.975 46.875 33.125 46.025 33.125 45C33.125 43.975 33.975 43.125 35 43.125H45C46.025 43.125 46.875 43.975 46.875 45C46.875 46.025 46.025 46.875 45 46.875Z" fill="url(#paint1_linear_85_477)"/>
<defs>
  <linearGradient id="paint0_linear_85_477" x1="6.55437" y1="5.6" x2="18.9689" y2="38.9928" gradientUnits="userSpaceOnUse">
    <stop stop-color="#175CFF"/>
    <stop offset="1" stop-color="#0A083B"/>
  </linearGradient>
  <linearGradient id="paint1_linear_85_477" x1="6.5536" y1="35.6" x2="18.9728" y2="68.9888" gradientUnits="userSpaceOnUse">
    <stop stop-color="#175CFF"/>
    <stop offset="1" stop-color="#0A083B"/>
  </linearGradient>
</defs>
</svg>
      
        

<Link to={'/IT-services'}>       <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
        IT Services
        </p> </Link>

        </div>

        <p className="text-md py-2 text-[#1E283F] leading-8">
        From network management to hardware solutions, we've got you covered
        </p>


        <div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'IT-services'}><button className='text-[#00329D]'>Learn More</button> </Link> 
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

      </div>


      <div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]  2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M43.9 5H16.025C9.95 5 5 9.95 5 16.025V32.275V32.775C5 38.875 9.95 43.8 16.025 43.8H25.625C27 43.8 28.125 44.925 28.125 46.3V48.725C28.125 50.1 27 51.225 25.625 51.225H19.575C18.55 51.225 17.7 52.075 17.7 53.1C17.7 54.125 18.525 55 19.575 55H40.45C41.475 55 42.325 54.15 42.325 53.125C42.325 52.1 41.475 51.25 40.45 51.25H34.4C33.025 51.25 31.9 50.125 31.9 48.75V46.325C31.9 44.95 33.025 43.825 34.4 43.825H43.925C50.025 43.825 54.95 38.875 54.95 32.8V32.3V16.05C54.925 9.95 49.975 5 43.9 5Z" fill="url(#paint0_linear_85_472)"/>
  <defs>
    <linearGradient id="paint0_linear_85_472" x1="6.55282" y1="6.5" x2="53.9396" y2="57.4337" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/software-development-services'}>     <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Software Development
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Our team of experts ensures seamless integration and top-notch performance.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'/software-development-services'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]  2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M48.0001 36.95C45.8501 38.925 43.0001 40.025 40.0751 40H15.9251C5.75005 39.275 5.72505 24.5 15.9251 23.775H16.0251C9.05005 4.37503 38.5251 -3.34997 41.9001 17C51.3251 18.2 55.1501 30.725 48.0001 36.95Z" fill="url(#paint0_linear_85_489)"/>
  <path d="M46.875 52.5C46.875 53.525 46.025 54.375 45 54.375H35C34.875 54.375 34.775 54.375 34.65 54.325C33.925 56.175 32.1 57.5 30 57.5C27.9 57.5 26.075 56.175 25.35 54.325C25.225 54.375 25.125 54.375 25 54.375H15C13.975 54.375 13.125 53.525 13.125 52.5C13.125 51.475 13.975 50.625 15 50.625H25C25.125 50.625 25.225 50.625 25.35 50.675C25.85 49.375 26.875 48.35 28.175 47.85C28.125 47.725 28.125 47.625 28.125 47.5V40H31.875V47.5C31.875 47.625 31.875 47.725 31.825 47.85C33.125 48.35 34.15 49.375 34.65 50.675C34.775 50.625 34.875 50.625 35 50.625H45C46.025 50.625 46.875 51.475 46.875 52.5Z" fill="url(#paint1_linear_85_489)"/>
  <defs>
    <linearGradient id="paint0_linear_85_489" x1="9.63429" y1="6.04729" x2="41.5551" y2="48.6516" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint1_linear_85_489" x1="14.1742" y1="40.525" x2="27.1809" y2="67.5139" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/cloud-services-and-solutions'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold ">
Cloud
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Our robust cloud solutions optimize scalability, security, and accessibility for your organization.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'/cloud-services-and-solutions'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M35.3749 55H24.5749C20.7249 55 17.8749 51.875 18.6249 48.55L19.9249 42.75C20.1749 41.6 21.1999 40.8 22.3749 40.8H37.5999C38.7749 40.8 39.7749 41.6 40.0499 42.75L41.3499 48.525C42.1499 52.1 39.4749 55 35.3749 55Z" fill="url(#paint0_linear_85_495)"/>
  <path d="M47.0501 34.225L44.3751 36.575C43.4501 37.375 42.2751 37.825 41.0751 37.825H18.9751C17.7501 37.825 16.5501 37.375 15.6501 36.55L13.0251 34.2C8.72506 30.675 8.70007 27.975 12.0001 23.825L25.0751 7.3C25.6196 6.56878 26.2513 6.00215 26.9219 5.58806C27.5026 5.22945 28.1751 5.70487 28.1751 6.38743V17.15C28.1501 18.225 29.0001 19.075 30.0501 19.05C31.1001 19.075 31.9251 18.225 31.9501 17.15V6.38743C31.9501 5.70487 32.6221 5.23099 33.2005 5.59334C33.857 6.00458 34.4775 6.56316 35.0501 7.275L48.0751 23.825C51.3251 27.975 51.2001 30.55 47.0501 34.225Z" fill="url(#paint1_linear_85_495)"/>
  <defs>
    <linearGradient id="paint0_linear_85_495" x1="19.2192" y1="41.226" x2="30.8719" y2="61.5214" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint1_linear_85_495" x1="10.9144" y1="5.98475" x2="40.8516" y2="45.9279" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/digital-product-design'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Design
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
A dedicated team of UX/UI designers unveiling the full potential of being design-driven
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'/digital-product-design'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M46.35 10.425L32.6 5.27498C31.175 4.74998 28.85 4.74998 27.425 5.27498L13.675 10.425C11.025 11.425 8.875 14.525 8.875 17.35V37.6C8.875 39.625 10.2 42.3 11.825 43.5L25.575 53.775C28 55.6 31.975 55.6 34.4 53.775L48.15 43.5C49.775 42.275 51.1 39.625 51.1 37.6V17.35C51.125 14.525 48.975 11.425 46.35 10.425ZM31.875 32.175V38.75C31.875 39.775 31.025 40.625 30 40.625C28.975 40.625 28.125 39.775 28.125 38.75V32.175C25.6 31.375 23.75 29.025 23.75 26.25C23.75 22.8 26.55 20 30 20C33.45 20 36.25 22.8 36.25 26.25C36.25 29.05 34.4 31.375 31.875 32.175Z" fill="url(#paint0_linear_85_501)"/>
  <defs>
    <linearGradient id="paint0_linear_85_501" x1="10.1877" y1="6.3891" x2="57.7045" y2="49.3386" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/cyber-security-services'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Cybersecurity
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Our holistic security solutions help you identify, protect, and recover from cyber threats.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'/cyber-security-services'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>




<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M29.85 5.525L23.825 19.55H17.8C16.8 19.55 15.825 19.625 14.875 19.825L17.375 13.825L17.475 13.6L17.625 13.2C17.7 13.025 17.75 12.875 17.825 12.75C20.725 6.025 23.975 3.925 29.85 5.525Z" fill="url(#paint0_linear_85_505)"/>
  <path d="M46.825 20.225L46.775 20.2C45.275 19.775 43.75 19.55 42.2 19.55H26.55L32.175 6.47499L32.25 6.29999C32.6 6.42499 32.975 6.59999 33.35 6.72499L38.875 9.04999C41.95 10.325 44.1 11.65 45.425 13.25C45.65 13.55 45.85 13.825 46.05 14.15C46.275 14.5 46.4501 14.85 46.5501 15.225C46.6501 15.45 46.725 15.65 46.775 15.875C47.15 17.15 47.175 18.6 46.825 20.225Z" fill="url(#paint1_linear_85_505)"/>
  <path d="M31.3 44.15H31.925C32.675 44.15 33.3 43.475 33.3 42.65C33.3 41.6 33 41.45 32.35 41.2L31.3 40.825V44.15Z" fill="url(#paint2_linear_85_505)"/>
  <path d="M45.7251 23.8C44.6001 23.475 43.4251 23.3 42.2001 23.3H17.8001C16.1001 23.3 14.5001 23.625 13.0001 24.275C8.6501 26.15 5.6001 30.475 5.6001 35.5V40.375C5.6001 40.975 5.6501 41.55 5.7251 42.15C6.2751 50.1 10.5251 54.35 18.4751 54.875C19.0501 54.95 19.6251 55 20.2501 55H39.7501C49.0001 55 53.8751 50.6 54.3501 41.85C54.3751 41.375 54.4001 40.875 54.4001 40.375V35.5C54.4001 29.975 50.7251 25.325 45.7251 23.8ZM33.2001 38.75C34.3501 39.15 35.9001 40 35.9001 42.65C35.9001 44.925 34.1251 46.75 31.9251 46.75H31.3001V47.3C31.3001 48.025 30.7251 48.6 30.0001 48.6C29.2751 48.6 28.7001 48.025 28.7001 47.3V46.75H28.4751C26.0751 46.75 24.1001 44.725 24.1001 42.225C24.1001 41.5 24.6751 40.925 25.4001 40.925C26.1251 40.925 26.7001 41.5 26.7001 42.225C26.7001 43.275 27.5001 44.15 28.4751 44.15H28.7001V39.925L26.8001 39.25C25.6501 38.85 24.1001 38 24.1001 35.35C24.1001 33.075 25.8751 31.25 28.0751 31.25H28.7001V30.7C28.7001 29.975 29.2751 29.4 30.0001 29.4C30.7251 29.4 31.3001 29.975 31.3001 30.7V31.25H31.5251C33.9251 31.25 35.9001 33.275 35.9001 35.775C35.9001 36.5 35.3251 37.075 34.6001 37.075C33.8751 37.075 33.3001 36.5 33.3001 35.775C33.3001 34.725 32.5001 33.85 31.5251 33.85H31.3001V38.075L33.2001 38.75Z" fill="url(#paint3_linear_85_505)"/>
  <path d="M26.7 35.35C26.7 36.4 27 36.55 27.65 36.8L28.6999 37.175V33.85H28.0749C27.2999 33.85 26.7 34.525 26.7 35.35Z" fill="url(#paint4_linear_85_505)"/>
  <defs>
    <linearGradient id="paint0_linear_85_505" x1="15.3405" y1="5.45937" x2="29.3632" y2="20.7149" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint1_linear_85_505" x1="27.188" y1="6.71774" x2="39.1281" y2="25.6511" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint2_linear_85_505" x1="31.3622" y1="40.9248" x2="34.2445" y2="42.7901" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint3_linear_85_505" x1="7.11716" y1="24.251" x2="33.7726" y2="68.4008" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint4_linear_85_505" x1="26.7621" y1="33.9497" x2="29.6444" y2="35.815" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/fintech-services'}>     <p className="text-[18px] py-2 text-[#1A2339] font-semibold leading-10">
Fintech Services
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Grow your business to the pinnacle while we handle your finance & accounting.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to={'/fintech-services'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>



</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M40.475 5H19.525C10.425 5 5 10.425 5 19.525V40.45C5 49.575 10.425 55 19.525 55H40.45C49.55 55 54.975 49.575 54.975 40.475V19.525C55 10.425 49.575 5 40.475 5ZM19.075 45.375C19.075 46.4 18.225 47.25 17.2 47.25C16.175 47.25 15.325 46.4 15.325 45.375V40.2C15.325 39.175 16.175 38.325 17.2 38.325C18.225 38.325 19.075 39.175 19.075 40.2V45.375ZM31.875 45.375C31.875 46.4 31.025 47.25 30 47.25C28.975 47.25 28.125 46.4 28.125 45.375V35C28.125 33.975 28.975 33.125 30 33.125C31.025 33.125 31.875 33.975 31.875 35V45.375ZM44.675 45.375C44.675 46.4 43.825 47.25 42.8 47.25C41.775 47.25 40.925 46.4 40.925 45.375V29.825C40.925 28.8 41.775 27.95 42.8 27.95C43.825 27.95 44.675 28.8 44.675 29.825V45.375ZM44.675 21.925C44.675 22.95 43.825 23.8 42.8 23.8C41.775 23.8 40.925 22.95 40.925 21.925V19.5C34.55 26.05 26.575 30.675 17.65 32.9C17.5 32.95 17.35 32.95 17.2 32.95C16.35 32.95 15.6 32.375 15.375 31.525C15.125 30.525 15.725 29.5 16.75 29.25C25.175 27.15 32.675 22.725 38.625 16.475H35.5C34.475 16.475 33.625 15.625 33.625 14.6C33.625 13.575 34.475 12.725 35.5 12.725H42.825C42.925 12.725 43 12.775 43.1 12.775C43.225 12.8 43.35 12.8 43.475 12.85C43.6 12.9 43.7 12.975 43.825 13.05C43.9 13.1 43.975 13.125 44.05 13.175C44.075 13.2 44.075 13.225 44.1 13.225C44.2 13.325 44.275 13.425 44.35 13.525C44.425 13.625 44.5 13.7 44.525 13.8C44.575 13.9 44.575 14 44.6 14.125C44.625 14.25 44.675 14.375 44.675 14.525C44.675 14.55 44.7 14.575 44.7 14.6V21.925H44.675Z" fill="url(#paint0_linear_85_514)"/>
  <defs>
    <linearGradient id="paint0_linear_85_514" x1="6.5536" y1="6.5" x2="53.9387" y2="57.4574" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/strategy-services'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Strategy Services
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Analyze your business goals, and acquire a thorough technology roadmap.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to="/strategy-services">
    <button className="text-[#00329D]">Learn More</button>
  </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M40.475 5H19.525C10.425 5 5 10.425 5 19.525V40.45C5 49.575 10.425 55 19.525 55H40.45C49.55 55 54.975 49.575 54.975 40.475V19.525C55 10.425 49.575 5 40.475 5ZM19.075 45.375C19.075 46.4 18.225 47.25 17.2 47.25C16.175 47.25 15.325 46.4 15.325 45.375V40.2C15.325 39.175 16.175 38.325 17.2 38.325C18.225 38.325 19.075 39.175 19.075 40.2V45.375ZM31.875 45.375C31.875 46.4 31.025 47.25 30 47.25C28.975 47.25 28.125 46.4 28.125 45.375V35C28.125 33.975 28.975 33.125 30 33.125C31.025 33.125 31.875 33.975 31.875 35V45.375ZM44.675 45.375C44.675 46.4 43.825 47.25 42.8 47.25C41.775 47.25 40.925 46.4 40.925 45.375V29.825C40.925 28.8 41.775 27.95 42.8 27.95C43.825 27.95 44.675 28.8 44.675 29.825V45.375ZM44.675 21.925C44.675 22.95 43.825 23.8 42.8 23.8C41.775 23.8 40.925 22.95 40.925 21.925V19.5C34.55 26.05 26.575 30.675 17.65 32.9C17.5 32.95 17.35 32.95 17.2 32.95C16.35 32.95 15.6 32.375 15.375 31.525C15.125 30.525 15.725 29.5 16.75 29.25C25.175 27.15 32.675 22.725 38.625 16.475H35.5C34.475 16.475 33.625 15.625 33.625 14.6C33.625 13.575 34.475 12.725 35.5 12.725H42.825C42.925 12.725 43 12.775 43.1 12.775C43.225 12.8 43.35 12.8 43.475 12.85C43.6 12.9 43.7 12.975 43.825 13.05C43.9 13.1 43.975 13.125 44.05 13.175C44.075 13.2 44.075 13.225 44.1 13.225C44.2 13.325 44.275 13.425 44.35 13.525C44.425 13.625 44.5 13.7 44.525 13.8C44.575 13.9 44.575 14 44.6 14.125C44.625 14.25 44.675 14.375 44.675 14.525C44.675 14.55 44.7 14.575 44.7 14.6V21.925H44.675Z" fill="url(#paint0_linear_85_514)"/>
  <defs>
    <linearGradient id="paint0_linear_85_514" x1="6.5536" y1="6.5" x2="53.9387" y2="57.4574" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>



<Link to={'/document-management-systems'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Document Management Systems
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
Simplified solutions for a paperless office.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">
<Link to="/document-management-systems">
    <button className="text-[#00329D]">Learn More</button>
  </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


<div className="transform transition-transform hover:scale-105 hover:shadow-gray-300 shadow-blue-200 shadow-lg    w-[300px] h-auto lg:h-[380px] md:w-[300px]   2xl:w-[382px] py-4  px-10 rounded-lg my-4">

<div className="flex gap-2">

<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M52.475 24.275C51.625 23.925 50.5 23.75 49.1 23.75H35.9C31.55 23.75 30 25.3 30 29.7V49.05C30 50.5 30.175 51.625 30.55 52.5C31.325 54.3 32.975 55 35.9 55H49.1C53.45 55 55 53.425 55 49.05V29.7C55 26.725 54.3 25.05 52.475 24.275ZM45 49.375H40C39.95 49.375 39.875 49.375 39.825 49.35C39.45 49.325 39.125 49.2 38.85 48.95C38.4 48.625 38.125 48.1 38.125 47.5C38.125 46.475 38.975 45.625 40 45.625H45C46.025 45.625 46.875 46.475 46.875 47.5C46.875 48.525 46.025 49.375 45 49.375Z" fill="url(#paint0_linear_85_525)"/>
  <path d="M52.4749 15.475V17.5C52.4749 18.875 51.3499 20 49.9749 20H35.8999C29.4999 20 26.2499 23.275 26.2499 29.7V52.5C26.2499 53.875 25.1249 55 23.7499 55H18.8749C17.8749 55 17.0749 54.2 17.0749 53.225C17.0749 52.225 17.8749 51.45 18.8749 51.45H23.7499V41.875H14.9999C9.4499 41.6 5.0249 37.025 5.0249 31.4V15.475C5.0249 9.7 9.7249 5 15.5249 5H41.9999C47.7749 5 52.4749 9.7 52.4749 15.475Z" fill="url(#paint1_linear_85_525)"/>
  <defs>
    <linearGradient id="paint0_linear_85_525" x1="7.5" y1="8" x2="53" y2="53" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
    <linearGradient id="paint1_linear_85_525" x1="6.5" y1="6.5" x2="54" y2="55" gradientUnits="userSpaceOnUse">
      <stop stop-color="#175CFF"/>
      <stop offset="1" stop-color="#0A083B"/>
    </linearGradient>
  </defs>
</svg>


<Link to={'/application-development-services'}>    <p className="text-[18px] py-2 text-[#1A2339] font-semibold">
Application Developement
</p> </Link>

</div>

<p className="text-md py-2 text-[#1E283F] leading-8">
We build stunning apps that capture the users' imaginations.
</p>


<div className="flex items-center gap-4  py-8 lg:py-16 xl:py-10   ">

 <Link to={'/application-development-services'}> <button className='text-[#00329D]'>Learn More</button> </Link>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
<path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="#00329D"/>
</svg>
</div>

</div>


      






    </div>

    </div>
  )
}

export default Services