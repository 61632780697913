import React, { useState } from "react";

const accordionData = [
  {
    title: "Can I make a free plan to get started?",
    content:
      "You can pay with a credit card or via net banking (if you’re in United States). We will renew your subscription automatically at the end of every billing cycle.",
  },
  {
    title: "How can I work with you and become your partner?",
    content:
      "You can pay with a credit card or via net banking (if you’re in United States). We will renew your subscription automatically at the end of every billing cycle.",
  },
  {
    title: "What services do your rate plans include?",
    content:
      "You can pay with a credit card or via net banking (if you’re in United States). We will renew your subscription automatically at the end of every billing cycle.",
  },
];

const Accordion = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {accordionData.map((item, index) => (
        <div key={index}>
          <h2 id={`accordion-collapse-heading-${index}`}>
            <button
              type="button"
              className={` my-6  flex items-center justify-between w-full  p-5   font-medium text-left text-white  ${
                openAccordion === index ? "rounded-t-xl" : ""
              }`}
              onClick={() => toggleAccordion(index)}
              data-accordion-target={`#accordion-collapse-body-${index}`}
              aria-expanded={openAccordion === index ? "true" : "false"}
              aria-controls={`accordion-collapse-body-${index}`}
            >
              <span>{item.title}</span>
            </button>
          </h2>
          <div
            id={`accordion-collapse-body-${index}`}
            className={`${openAccordion === index ? "" : "hidden"}`}
            aria-labelledby={`accordion-collapse-heading-${index}`}
          >
            <div className="p-5 border border-b-0 border-black shadow-2xl">
              <p className="mb-2 text-white">
                {item.content}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
