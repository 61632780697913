import React from "react";
import { Link } from "react-router-dom";



const Hero = () => {
  return (
    <>

 
<div class="py-16  lg:py-48 px-4  lg:h-[750px] flex justify-between items-center  bg-custom " >


  <div class="sm:max-w-[600px] md:max-w-[700px] md:container md:mx-auto lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto">
    <h2 class="py-2 sm:text-center md:text-center lg:leading-[60px] xl:w-[950px] lg:py-4 text-2xl text-justify lg:text-left lg:text-[62px] font-bold text-[#00174B]">
      We help startups and agencies to achieve their goal.
    </h2>
    <p class="text-md lg:py-4 py-2  sm:text-center md:text-center  text-justify lg:text-left lg:mb-4 text-sm lg:text-md lg:text-[24px] text-[#00174B] lg:leading-10" >
      Minimalism, optimization and precision, your business is skyrocketing.
    </p>

     <div class="flex flex-col md:justify-center lg:justify-start md:flex-row md:space-x-4 py-2 gap-y-4 lg:flex-row lg:space-x-10">
    <Link to={'/about-us'}> <button class="text-white w-full bg-[#175CFF] text-sm lg:text-[18px] px-10 py-3 lg:py-4  rounded-[30px] ">
      Learn More
      </button> </Link> 





      <Link to={'/contact-us'}>     <button class="bg-white w-full text-[#175CFF] text-sm lg:text-[18px] px-10 py-3 lg:py-4  rounded-[30px] border-2 border-blue-500 ">
        Contact Us
      </button> </Link> 
    </div>




  </div>

</div>



      
<div className="bg-[#F4F4F4] p-4 sm:max-w-[550px] sm:-mt-8 md:max-w-[700px] sm:container sm:mx-auto lg:max-w-[700px] xl:max-w-[900px] 2xl:max-w-[1100px] lg:mx-auto md:-mt-10 l rounded-lg shadow-lg lg:-mt-24 ">
  <p className="lg:p-20 text-md lg:text-lg  text-custom-gray leading-6 lg:leading-10" >At <span className="text-custom-blue">  BlackBerry Technologies</span> , we believe in the transformative power of technology. With a passion for innovation and a commitment to excellence, we are dedicated to providing top-notch IT solutions that drive your business forward. Our team of experts combines expertise with a client-centric approach, ensuring that every solution we deliver is tailored to meet your unique needs.
  <br /> <span className="text-black  text-md lg:text-lg "> Welcome to a world of limitless possibilities! </span></p>
</div>

  

    


    </>
  );
};

export default Hero;
