import React from "react";
import cyber from "./assets/cyber.png";
import Talk from "../Talk";
import Button from "../Button";
import { Helmet } from "react-helmet";

const Cyber = () => {
  return (
    <div>
      <Helmet>
        <title>
          Cyber Security Services & Solutions - BlackBerry Technologies
        </title>
        <meta
          name="description"
          content="Protect your digital assets with our cutting-edge cyber security services and solutions. Visit www.blackberrytechnologies.net for more!."
        />
      </Helmet>

      <div className="bg-cyber">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2 text-center leading-[60px] lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
                Cybersecurity Services
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              Our holistic security solutions help you identify, protect, and recover from cyber threats.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={cyber}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
      <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-16 items-center py-8">
     
          <div className="bg-[#F6F9FF]  w-[370px] h-[280px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              Managed Detection & Response
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            With the power of AI and human expertise, we detect, analyse, and respond to threats proactively to provide cohesive defense and central monitoring.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px]  h-[280px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Threat & Vulnerability management
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            We Identify, classify remediate, and mitigate high-risk areas through an iterative process to secure the crown jewels of the organization.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[280px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Governance Risk & Compliance
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Our tailored solutions establish, manage and attain a mature state of Enterprise GRC for achieving strategic business objectives & Goals of an organization.
            </p>
          </div>
          <div className="bg-[#F6F9FF]  w-[370px] h-[280px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Data Protection & Privacy
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Deliver trusted experience to customers by securing the privacy, availability, and integrity of their data with a holistic and adaptive approach.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[280px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            IoT/OT Security
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            Continuous advancement of IoT/OT security posture will enhance the visibility, security, and monitoring of the threat and vulnerability landscape.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[280px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Security Implementation Service
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            A powerful cybersecurity servies implementation to safeguard your assets from evolving digital threats.
            </p>
          </div>


        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between items-start">
            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Compromise Assessment
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Proactively identify vulnerabilities and potential breaches in your network and systems through in-depth assessments and analysis.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Data Privacy and Protection
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Implement robust measures to safeguard sensitive information, ensuring compliance with data protection regulations and maintaining customer trust.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Cloud Security
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Secure your cloud environment with advanced protocols and solutions, guaranteeing the safety of your data and applications in the cloud.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Threat Hunting
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Actively search for and mitigate potential threats before they can cause harm, using advanced tools and techniques to stay one step ahead of cyber adversaries.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Security Solution Deployment
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Efficiently implement and integrate cutting-edge security solutions tailored to your specific business needs, providing comprehensive protection against cyber threats.
              </p>
            </div>

            <div className=" w-[379px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Incident Management
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Rapidly respond to security incidents, minimizing damage and ensuring a swift return to normal operations through effective planning and execution.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Cyber;
