import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Scroll from './components/Scroll';

import Home from './components/home/Home';
import Navbar from './components/home/Navbar';
import Footer from './components/home/Footer';
import About from './components/pages/about/About';
import Contact from './components/pages/Contact';

import IT from './components/services/IT';
import Software from './components/services/Software';
import Document from './components/services/Document';
import Strategy from './components/services/Strategy';
import Fintech from './components/services/Fintech';
import Cyber from './components/services/Cyber';
import Design from './components/services/Design';
import Cloud from './components/services/Cloud';

import PricingPlan from './components/pages/PricingPlan';
import Application from './components/services/Application';

function App() {
  return (
    <Router>
      <Scroll />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />

        <Route path="/IT-services" element={<IT />} />
        <Route path="/software-development-services" element={<Software />} />
        <Route path="/document-management-systems" element={<Document />} />
        <Route path="/strategy-services" element={<Strategy />} />
        <Route path="/fintech-services" element={<Fintech />} />
        <Route path="/cyber-security-services" element={<Cyber />} />
        <Route path="/digital-product-design" element={<Design />} />
        <Route path="/cloud-services-and-solutions" element={<Cloud />} />
        <Route path="/application-development-services" element={<Application />} />


        <Route path="/plans" element={<PricingPlan />} />
      </Routes>
     <Footer />
    </Router>
  );
}

export default App;
