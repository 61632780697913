import React from 'react'
import it from './assets/it.png'
import Talk from '../Talk'
import Button from '../Button'
import { Helmet } from 'react-helmet'


const IT = () => {
  return (
    <div >


<Helmet>
        <title>Information Technology (IT) Services - BlackBerry Technologies</title>
        <meta name="description" content="Get the help you need with information technology (IT) services from experts at BlackBerry Technologies. We have what you need!" />
    
      </Helmet>

<div className='bg-it'>


<div className="  py-16 lg:py-36 px-4   ">
  
<div className='flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto '>

<div className=" py-8 lg:py-0 lg:w-1/2  ">
  <h3 className="py-2 text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
  IT Services
  </h3>
  <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
  From network management to hardware solutions, we've got you covered.
  </p>
 <div className='flex justify-center lg:justify-start'>
 <Button />
 </div>

</div>


<img src={it} className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "  alt="" />


</div>

</div>

</div>

  {/* our services */}

  <div className='px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24'>

  <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

    <div className='flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8'>

<div className='bg-[#F6F9FF]  w-[280px] h-[350px] shadow-lg  p-8'>
<p className='text-[#052472] text-xl font-semibold text-left'>Managed Services</p>
<p className='text-custom-gray text-md py-2 text-left leading-8 ' >Technology and users have many issues, nothing is perfect. We have the tools that restore, recover, and secure, in short, to manage your IT services automation</p>
</div>


<div className='bg-[#F6F9FF]  w-[280px]  h-[350px] shadow-lg   p-8'>
<p className='text-[#052472] text-xl font-semibold text-left'>Consulting Services</p>
<p className='text-custom-gray text-md py-2 text-left leading-8'>Stay away from moments of trouble and don’t take a chance when it comes to your company. Leave it to us, our IT professional consultation will make it simple for you.</p>
</div>



<div className='bg-[#F6F9FF]  w-[280px] h-[350px] shadow-lg   p-8'>
<p className='text-[#052472] text-xl font-semibold text-left'>IT Outsourcing</p>
<p className='text-custom-gray text-md py-2 text-left leading-8'>Outsource your IT service management to us and avoid unexpected troubles and unreliable systems. We create innovative, yet effective solutions to help your business run smoothly</p>
</div>




<div className='bg-[#F6F9FF]  w-[280px] h-[350px] shadow-lg  p-8'>
<p className='text-[#052472] text-xl font-semibold text-left'>IT Support</p>
<p className='text-custom-gray text-md py-2 text-left leading-8'>Instantly connect to IT services and support experts who can provide you the fix you need. Our service has the industry’s fastest and seamless service level.</p>
</div>



    </div>


  </div>


   {/* why client choose us */}

<div className='bg-[#175CFF]'>



   <div className='px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 '>
    <h3 className='text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]'>Why do clients choose us?</h3>

    <div className='py-12 flex flex-wrap gap-4   sm:justify-between justify-between items-center'>

<div className=' w-[379px] py-8'>


  <p className='text-lg text-white  py-2 text-[24px] font-[600]'>Servers</p>
  <p className='text-white  leading-8 text-[18px] text-left'>Sophisticated performance engines that power the infrastructure for IoT software, AI, blockchain, data analytics, and other cutting-edge innovations.</p>

</div>



<div className=' w-[379px] py-8'>


  <p className='text-lg text-white  py-2 text-[24px] font-[600]'>Storage</p>
  <p className='text-white  leading-8 text-[18px] text-left'>Build enterprise storage on the cloud or on-premise. Heightened scale and hybrid solutions that you can cherry-pick to suit your business needs.</p>

</div>


<div className=' w-[379px] py-8'>


  <p className='text-lg text-white  py-2 text-[24px] font-[600]'>Software</p>
  <p className='text-white  leading-8 text-[18px] text-left'>Manage and control your workloads more efficiently using unique software solutions that function across platforms and manages the usage dynamics</p>

</div>




  </div>

   </div>

   </div>



   {/* lets talk */}

   <Talk />

    </div>
  )
}

export default IT