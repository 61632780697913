import React from "react";

import { useForm, ValidationError } from '@formspree/react';

const Newsletter = () => {


  const [state, handleSubmit] = useForm("mknlloey");
  if (state.succeeded) {
      return(

        <h2 className="text-4xl text-blue-500 text-center py-36 font-bold">Thanks for subscribing us.we will get back to you soon! </h2>

      ) 
      ;
  }


  return (
    <div className="py-8 lg:py-32 bg-[#175CFF] mx-4 px-4 sm:container sm:mx-auto sm:max-w-[600px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto">
      <p className="text-2xl text-center lg:text-4xl font-bold md:text-justify sm:text-center text-white py-2 lg:text-center">
        Subscribe to the newsletter!
      </p>
      <p className="text-md  font-bold  text-center text-white py-2">
        Enter your email into form below to get updates from us
      </p>
      <div className="flex lg:flex-row  justify-center pt-4">
        <form  onSubmit={handleSubmit}>
          <input
            className=" w-full lg:w-[350px] lg:px-8 py-4"
            type="text"
            placeholder=" Write your Email"
            id="message"
            name="message"
          />
          <button    type="submit" className="bg-blue-500 w-full lg:w-[150px]  hover:bg-custom-gray  focus:outline-none text-white font-bold py-4 px-8 lg:px-10  mt-4">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
