import React from "react";
import document from "./assets/document.png";
import Talk from "../Talk";
import Button from "../Button";

import { Helmet } from "react-helmet";

const Document = () => {
  return (
    <div>

<Helmet>
        <title>Document Management Systems - BlackBerry Technologies</title>
        <meta name="description" content="Our document management solutions (DMS) help you to manage and track paper-based documents to end your paper trail and increase efficiency." />
    
      </Helmet>

      <div className="bg-document">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2 leading-[60px] text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
              Document Management Systems
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              Simplified solutions for a paperless office.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={document}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
      <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-16 items-center py-8">
     
          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Document Management Software
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Find all the information with a click of a button with our software, hardware, comprehensive training & implementation and get your paper under control.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Document ScanningServices
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            Secure document scanning and imaging services that unlock the value of all the information stored in their file cabinets, storage boxes and paper documents.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Automated Electronic Forms
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
           
            Speed up your business’s workflow by capturing, transferring and streamlining how you collect information with automated forms.

            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            High Speed Scanners
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
           
            Increase efficiency and productivity by adding a scanner to your office. Stop worrying about not being able to find information and increase efficiency.

            </p>
          </div>

          
          <div className="bg-[#F6F9FF]  w-[370px] h-[300px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Customized Software
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
           
            Our custom software development services and programming solutions can be tailored to your company's specific document management requirements, resulting in increased productivity.

            </p>
          </div>

        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between  items-start">
         
            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Software Development
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              We have a dedicated Software Development Team creating custom applications such as TrackSuite Software and ClickScan
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Trained Technicians
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              We possess over 125 Service certifications from our hardware and software vendors.
              </p>
            </div>

            <div className=" w-[376px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Maintenance Contracts
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Our customers have entrusted our company with maintenance contracts on over 1,100 units of imaging hardware equipment.
              </p>
            </div>

        
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Document;
