import React from "react";
import software from "./assets/software.png";
import Talk from "../Talk";
import Button from "../Button";
import { Helmet } from "react-helmet";

const Software = () => {
  return (
    <div>
      <Helmet>
        <title>Software Development Services - BlackBerry Technologies</title>
        <meta
          name="description"
          content="Ready to take on the next big software project? Let BlackBerry Technologies help you get there! Click and see why we are top-rated."
        />
      </Helmet>

      <div className="bg-software">
        <div className="  py-16 lg:py-36 px-4   ">
          <div className="flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap  lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-2 lg:leading-[60px] text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-[62px] text-custom-blue 2xl:py-10 ">
                Software Development
              </h3>
              <p className="text-md text-center lg:text-left lg:text-[24px]  text-custom-gray">
              Our team of experts ensures seamless integration and top-notch performance.
              </p>
              <div className="flex justify-center lg:justify-start">
                <Button />
              </div>
            </div>

            <img
              src={software}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto  py-16 lg:py-24">
      <h3 className="text-2xl pl-10 lg:pl-0 text-left  lg:text-3xl text-custom-black py-8 font-semibold 2xl:text-[48px]">
          Our Services
        </h3>

        <div className="flex flex-wrap justify-center  gap-4 sm:space-x-4  lg:space-x-0  lg:gap-x-2 lg:gap-y-8  lg:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
              DevOps
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8 ">
            We provide integrated solutions so that you can eliminate manual steps, reduce errors, and scale faster by automating release cycles and, service virtualization.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px]  h-[310px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Quality Engineering
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            We work with you to ensure QA execution is always up to the mark. Right from defining the test requirements, test strategy, planning, and execution, we cover it all.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg   p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Embedded Services
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            With us, you get new-age embedded software development services like ASIC, FPGA, and DSPs, etc, and all software, hardware, mechanical, and PCB needs.
            </p>
          </div>

          <div className="bg-[#F6F9FF]  w-[280px] h-[310px] shadow-lg  p-8">
            <p className="text-[#052472] text-xl font-semibold text-left">
            Data Engineering
            </p>
            <p className="text-custom-gray text-md py-2 text-left leading-8">
            We mine data, treat, and repeat the same process until it yields results for initiating new businesses, advising strategic changes, or accessing new markets.
            </p>
          </div>
        </div>
      </div>

      {/* why client choose us */}

      <div className="bg-[#175CFF]">
        <div className="px-4 sm:max-w-[500px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] sm:container sm:mx-auto  py-16 lg:py-24 ">
          <h3 className="text-2xl text-center sm:text-left  font-semibold text-white lg:text-[48px]">
            Why do clients choose us?
          </h3>

          <div className="py-12 flex flex-wrap gap-4   sm:justify-between justify-between items-center">

            <div className=" w-[379px]  2xl:w-[587px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Wide Tech-stack
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              You approach us with your requirements, and we do the rest. Being a dedicated software development company, we envision the gameplay from conceptualization to support, there are a number of factors that we micromanage, so as to push the limit with each new solution we create.
              </p>
            </div>

            <div className=" w-[379px] 2xl:w-[587px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Metric Driven
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Today, Engineering is at the heart of the functioning. Our aim is to work with your goals in mind to provide you with competent engineering services like you have not experienced before! We promise a seamless trustworthy journey.
              </p>
            </div>

            <div className=" w-[379px] 2xl:w-[587px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Competitive Value
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              Right from initiating the structure, developing to deploying, we add value and ability to combine competitive pricing with the right scale to fine-tune our service efficiency for you. Our prices are based on fresh, accurate data on products and prices.
              </p>
            </div>

            <div className=" w-[379px] 2xl:w-[587px] py-8">
              <p className="text-lg text-white  py-2 text-[24px] font-[600]">
              Integrated Delivery
              </p>
              <p className="text-white  leading-8 text-[18px] text-left">
              When it’s finally time to deliver the code, our tech ninjas work together to execute its creation on time. From the planning, structuring, coding, and developing to deploy, our seamless process helps deliver to you the solution of your needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* lets talk */}

      <Talk />
    </div>
  );
};

export default Software;
