import React from "react";
import about1 from "./assets/about-1.png";
import about2 from "./assets/about-2.png";
import about3 from "./assets/about-3.png";

const WhyChose = () => {
  return (
    <div className=" px-4 sm:max-w-[600px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1100px]  2xl:max-w-[1300px] sm:container sm:mx-auto">

<div className=" flex justify-between items-center lg:items-start  flex-wrap">

  
<img className="object-cover lg:w-1/2 w-full" src={about2} alt="" />

<div className="w-[570px] md:w-full lg:w-1/2 ">
  <h3 className="py-2 sm:text-center lg:text-left lg:py-4 font-semibold text-2xl lg:text-[48px] text-[#00174B]">
    Why Choose Us
  </h3>
  <p className="text-md py-4 leading-5 lg:leading-8 lg:text-[18px] text-left sm:text-center lg:text-left text-[#1E283F]">
  In a rapidly evolving digital landscape, choosing the right technology partner is crucial. At BlackBerryTechnologies, we are not just experts; we're innovators on the forefront of technological advancement. Here's why you should entrust us with your IT solutions:
  </p>






</div>

</div>
     



      <div className="py-8 lg:py-16  flex justify-between items-center lg:items-start flex-wrap">

        <div className="w-[455px] sm:w-full lg:w-1/2 ">
          <h3 className="py-2 lg:leading-[50px] font-semibold text-2xl lg:text-[48px] text-[#00174B] sm:text-center lg:text-left ">
            The habits of Our Startup
          </h3>
          <p className="text-md lg:text-[18px] lg:py-4 text-left text-custom-black leading-5 lg:leading-8 sm:text-center lg:text-left">
            “Design is not just what it looks like and feels like. Design is how
            it works”. We always try to make a great output by this culture.
            Steve Jobs.
          </p>

          <div className="flex pt-8 gap-4 w-full xl:w-[500px] xl:-ml-6 sm:justify-center md:gap-20  lg:gap-16">

          <ul className="">

            <div className="flex gap-2 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px] py-2">Cutting Edge Expertize</li>
            </div>

            <div className="flex gap-2 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px]  py-2">Agile Developement</li>
            </div>


            <div className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px]  py-2">Security Pioneers</li>
            </div>
          
          
          </ul>

          
          <ul>

            <div className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px]  py-2">Scalable Cloud Solutions</li>
            </div>

            <div className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px]  py-2">Data-Driven Insights</li>
            </div>


            <div className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M3.39843 7.8242L0.136718 4.58202C0.0455727 4.49088 0 4.37369 0 4.23046C0 4.08723 0.0455727 3.97004 0.136718 3.8789L0.859373 3.17577C0.950519 3.07161 1.06445 3.01953 1.20117 3.01953C1.33789 3.01953 1.45833 3.07161 1.5625 3.17577L3.74999 5.36327L8.43748 0.675781C8.54165 0.571615 8.66209 0.519531 8.79881 0.519531C8.93553 0.519531 9.04946 0.571615 9.1406 0.675781L9.86326 1.3789C9.9544 1.47005 9.99998 1.58724 9.99998 1.73047C9.99998 1.87369 9.9544 1.99088 9.86326 2.08203L4.10155 7.8242C4.01041 7.92837 3.89322 7.98045 3.74999 7.98045C3.60676 7.98045 3.48958 7.92837 3.39843 7.8242Z" fill="#36B37E"/>
</svg>
            <li className="text-[#57586E] text-md lg:text-[16px]  py-2">AI Powered Solutions</li>
            </div>
          
          
          </ul>
        


          </div>




        </div>

        <img className="xl:w-[500px] md:w-full lg:w-1/2 pt-8 lg:pt-0 " src={about3} alt="" />
      </div>
    </div>
  );
};

export default WhyChose;
